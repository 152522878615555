<template>
  <LiefengContent>
    <template v-slot:title> 居民审核 </template>
    <template v-slot:toolsbarLeft>
      <LiefengTree
        ref="mychild"
        :fixedmenu="true"
        @handleData="getList"
        treeID="mychild"
        fixedContentID="contentarea"
        style="width: 200px"
      ></LiefengTree>
    </template>
    <template v-slot:toolsbarRight>
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.name"
        placeholder="姓名"
        style="width: 150px; margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Input
      :maxlength="20"
        enter-button
        v-model.trim="searchData.phone"
        placeholder="手机号"
        style="width: 150px;margin-right: 10px"
        @on-enter="hadlePageSize"
      />
      <Button
        style="margin: 0 10px 0 0"
        type="primary"
        icon="ios-search"
        @click="getList"
        >查询</Button
      >
      <Button
        style="margin: 0 10px 0 0"
        type="success"
        icon="ios-refresh"
        @click="rest"
        >重置</Button
      >
       <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button type="error" v-if="$route.query.name" @click="$router.push(`/${$route.query.name}`)">返回</Button>
      <Icon
        type="md-more"
        @click="selectQuery = true"
        style="font-size: 24px; line-height: 32px; cursor: pointer"
        title="更多查询条件"
      />

      <Drawer
          title="查询条件"
          v-model="selectQuery"
          width="320"
          :styles="{
            height: 'calc(100% - 55px)',
            overflow: 'auto',
            paddingBottom: '53px',
            position: 'static',
          }"
        >
          <Form :model="searchData" :label-colon="true">
            <FormItem label="姓名">
              <Input
                v-model.trim="searchData.name"
                placeholder="请输入姓名"
                :maxlength="20"
              ></Input>
            </FormItem>
            <FormItem label="手机号">
              <Input
                :maxlength="20"
                v-model.trim="searchData.phone"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
            <FormItem label="身份类型">
               <Select v-model="searchData.userType" placeholder="请选择身份类型" clearable>
                  <Option value="4">租赁人员</Option>
                  <Option value="3">是业主且户籍在此</Option>
                  <Option value="2">是业主但户籍不在此</Option>
                  <Option value="1">仅户籍在此</Option>
                  <Option value="5">其他</Option>
              </Select>
            </FormItem>
            <FormItem label="详细地址">
              <Input
                :maxlength="20"
                v-model.trim="searchData.detailAddr"
                placeholder="请输入详细地址"
                style="width: 100%"
              />
            </FormItem>
             <FormItem label="家庭联络人">
               <Select v-model="searchData.contact" placeholder="请选择是否家庭联络人" clearable>
                  <Option value="1">是</Option>
                  <Option value="2">否</Option>
              </Select>
            </FormItem>
          </Form>
          <div class="demo-drawer-footer">
            <Button style="margin-right: 8px" @click="selectQuery = false"
              >取消</Button
            >
            <Button type="primary" @click="getList">查询</Button>
          </div>
        </Drawer>
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        height="200"
        :loading="loading"
        :fixTable="true"
        :curPage="curPage"
        :pagesizeOpts="[20, 30, 50, 100]"
        :total="total"
        :page-size="pageSize"
        @hadlePageSize="hadlePageSize"
      ></LiefengTable>
      <!-- 驳回框 -->
      <LiefengModal
        title="居民信息驳回"
        width="30%"
        :value="rejectModal"
        @input="rejectModalFn"
      >
        <template v-slot:contentarea>
          <Form
            ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="80"
          >
            <FormItem label="驳回原因" prop="reason">
              <Input
              :maxlength="20"
                v-model.trim="formValidate.reason"
                placeholder="请输入驳回原因"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="rejectModal = false" style="margin-right: 8px"
            >取消</Button
          >
          <Button type="primary" @click="rejectEvent('formValidate')"
            >确认</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/residentindex")
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengTree from "@/components/LiefengTree";
import LiefengModal from "@/components/LiefengModal";
export default {
   watch: {
    selectQuery(newV) {
      if (!newV) {
        this.searchData.name = '';
            this.searchData.phone = '';
            this.searchData.userType = '';
            this.searchData.detailAddr = '';
            this.searchData.contact = '';
      }
    },
  },
  data() {
    return {
      changeNum:0,
       // 查询更多
      selectQuery: false,
         loading: false,
        searchData: {
        name: "",
        phone: "",
        pageSize: 20,
        page: 1,
      },
      total: 0,
      status: 1,
      inviterFlag: 1, //1 居民 2 成员
      treeParams: {
        inviterFlag: 1, //1 居民 2 成员
        status: 1, //必填
        roadCode: "",
        projectCode: "",
        communityCode: "", //必填
        pageSize: 20,
        page: 1,
        name: "",
        phone: "",
        oemCode: "", //必填  缓存拿
      },
      treeData: [],
      talbeColumns: [
        {
          title: "姓名",
          key: "name",
          align: "center",
          width: 100,
        },
        {
          title: "性别",
          key: "sexLabel",
          align: "center",
          width: 80,
        },
        {
          title: "联系电话",
          key: "phone",
          width: 130,
          align: "center",
        },
        {
          title: "身份类型",
          key: "userTypeLabel",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.userTypeLabel,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.userTypeLabel
              ),
            ]);
          },
        },
        {
          title: "出生日期",
          key: "birthdayValue",
          width: 120,
          align: "center",
        },
        {
          title: "街巷",
          key: "roadName",
          align: "center",
          width: 100
        },
        {
          title: "所在详细区域",
          key: "detailAddr",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                  domProps: {
                    title: params.row.detailAddr,
                  },
                  on: {
                    click: (e) => {
                      e.stopPropagation();
                    },
                  },
                },
                params.row.detailAddr
              ),
            ]);
          },
        },
        {
          title: "是否家庭户代表",
          key: "contactLabel",
          align: "center",
          width: 150
        },
        {
          title: "操作",
          slot: "action",
          width: 180,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },

                  on: {
                    click: () => {
                      this.througth(params.row);
                    },
                  },
                },
                "通过"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    margin: "0 5px",
                  },
                  on: {
                    click: () => {
                      this.reject(params.row);
                    },
                  },
                },
                "驳回"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  on: {
                    click: () => {
                      this.remove(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      tableData: [],
      selection: [],
      showDo: true,
      hideEdit: true,
      hidenBread: true,
      hideDel: true,
      hideSearch: false,
      curPage: 1,
      pageSize: 20,
      orgCode: "",
      rejectModal: false,
      rejectParams: {},
      familyMemberId: "",
      formValidate: {
        reason: "",
      },
      ruleValidate: {
        reason: [
          {
            required: true,
            message: "驳回原因不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "姓名",
            "性别",
            "联系电话",
            "身份类型",
            "出生日期",
            "街巷",
            "所在详细区域",
            "是否家庭户代表",
          ];
          let filterVal = [
            "name",
            "sexLabel",
            "phone",
            "userTypeLabel",
            "birthdayValue",
            "roadName",
            "detailAddr",
            "contactLabel"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "居民审核列表"
          );
        },
      });
    },
       //驳回框事件
    rejectModalFn(status) {
      this.rejectModal = status;
    },
    //
    rejectEvent(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.rejectParams = {
            familyMemberId: this.familyMemberId,
            reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
            name: parent.vue.loginInfo.userinfo.nickName,
            status: 3,
            result: this.formValidate.reason,
          };
          this.$post(
            "/syaa/pc/sy/user/residents/changeSyFamilyMember",
            this.rejectParams
          )
            .then((res) => {
              if (res.code == "200") {
                this.$Message.success("操作成功");
                let data = {
                  pageSize: this.pageSize,
                  page: this.curPage,
                };
                this.getList(data);
                this.rejectModal = false;
                this.formValidate.reason = "";
              } else {
                this.$Message.error("操作失败！");
              }
            })
            .catch((err) => {
              this.$Message.error("操作失败！");
            });
        }
      });
    },
    reject(data) {
     if (data) {
        // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
        let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
        this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
        this.curPage = this.curPage < 1 ? 1 : this.curPage;
        this.rejectModal = true;
        this.familyMemberId = data.familyMemberId;
      } else {
        this.$Message.error("请先选择数据");
      }
    },
    // 删除
    remove(row) {
      if (row) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "<p>确认删除数据</p>",
          cancelText: "取消",
          okText: "确认",
          onOk: () => {
            // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
            let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
            this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
            this.curPage = this.curPage < 1 ? 1 : this.curPage;

            this.$post("/syaa/pc/sy/user/residents/delFamilyMember", {
              familyMemberId: row.familyMemberId,
            })
              .then((res) => {
                if (res.code === "200") {
                  this.$Message.success("删除成功");
                  let data = {
                    pageSize: this.pageSize,
                    page: this.curPage,
                  };
                  this.getList(data);
                } else {
                  this.$Message.error("删除失败！");
                }
              })
              .catch((err) => this.$Message.error("删除失败！"));
          },
        });
      } else {
        this.$Message.error("请先选择数据");
      }
    },
    //通过
    througth(data) {
      let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
      this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
      this.curPage = this.curPage < 1 ? 1 : this.curPage;
      if (data) {
        this.$Modal.confirm({
            title: '温馨提示',
          content: "确认通过该数据？",
          onOk: () => {
            let params = {
              familyMemberId: data.familyMemberId,
              reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
              name: parent.vue.loginInfo.userinfo.nickName,
              status: 2,
            };
            // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
            let totalPage = Math.ceil((this.total - 1) / this.pageSize); // 总页数
            this.curPage = this.curPage > totalPage ? totalPage : this.curPage;
            this.curPage = this.curPage < 1 ? 1 : this.curPage;
            this.$post(
              "/syaa/pc/sy/user/residents/changeSyFamilyMember",
              params
            )
              .then((res) => {
                if (res.code == "200") {
                  this.$Message.success("操作成功");
                  let data = {
                    pageSize: this.pageSize,
                    page: this.curPage,
                  };
                  this.getList(data);
                }else {
                    this.$Message.error("操作失败");
                }
              })
              .catch((err) => {
                this.$Message.error("操作失败");
              });
          },
        });
      }
    },
    //重置
    rest() {
      this.searchData.phone = "";
      this.searchData.name = "";
      this.searchData.userType = "";
      this.searchData.detailAddr = "";
      this.searchData.contact = "";
      this.curPage = 1;
       this.pageSize = 20;
      this.getList();
    },
    //page页面改变
    hadlePageSize(obj) {
      this.getList(obj);
    },
    //菜单树
    getList(data) {
      console.log(data);
      this.loading = true;
      if (data) {
        // if(this.$route.query.code && this.changeNum == 0){
        //   this.changeNum++
        //   data.communityCode = this.$route.query.code
        // }
        //第一次进来页面就会执行
        if (data.communityCode) {
          this.searchData.name = "";
          this.searchData.phone = "";
          this.orgCode = data.communityCode;
          this.orgName = data.orgName;
          this.tableData = [];
          this.treeParams = {
            ...data,
            communityCode:data.communityCode,
            status: this.status, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode: parent.vue.loginInfo.userinfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
            inviterFlag: 1,
          };
          this.curPage = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.treeParams.inviterFlag = 1;
          this.curPage = data.page;
          this.pageSize = data.pageSize;
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
         this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.userType = this.searchData.userType;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.inviterFlag = 1;

         this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.treeParams.userType = this.searchData.userType;
          this.treeParams.detailAddr = this.searchData.detailAddr;
          this.treeParams.contact = this.searchData.contact;
          this.curPage = 1;
        }
      } else {
        //重置的时候执行
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 1, //必填
           name: this.searchData.name,
          phone: this.searchData.phone,
          userType: this.searchData.userType,
          detailAddr: this.searchData.detailAddr,
          contact: this.searchData.contact,
          oemCode: parent.vue.loginInfo.userinfo.oemCode,
          page: this.curPage,
          pageSize: this.pageSize,
          inviterFlag: 1, //1 居民 2 成员
        };
      }
      console.log(this.treeParams);
      //请求页面表格数据
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        this.treeParams
      )
        .then((res) => {
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.curPage = res.currentPage;
            this.treeParams.name = "";
            this.treeParams.phone = "";
            this.loading = false;
            this.selectQuery = false;
             this.searchData.name = '';
            this.searchData.phone = '';
            this.searchData.userType = '';
            this.searchData.detailAddr = '';
            this.searchData.contact = '';
          } else {
            this.$message.error({
              background: true,
              content: "获取数据失败！",
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          this.$message.error({
            background: true,
            content: "获取数据失败！",
          });
          this.loading = false;
        });
    },
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
    LiefengTree
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-drawer-body {
  .ivu-form-item {
    width: 100%;
     margin-bottom: 0;
  }
}
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>